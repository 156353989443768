@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

$font: 'Roboto',sans-serif;

$BackGround: rgb(7,7,7);
$Primary: rgb(35,35,35);
$Offest: rgba(54, 53, 53,.5);
$TextColor: rgb(134, 111, 90);
$light: #FFF;

$borderRadius: 12px;
$headerHeight: 65px;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-widht:48em) {
            @content;
        }
    }
}